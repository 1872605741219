<div *ngIf="msgError !== ''" class="message-box error mb-12">
	{{ msgError }}
	<button mat-icon-button color="primary" class="close-button" color="primary" (click)="hideError()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<h2 mat-dialog-title class="m-0 mb-8">{{ "clima.modal.title" | translate }}</h2>

@if (projectConfig.tandemMode) {
	@if (step == 0) {
		<mat-dialog-content class="mat-typography">
			@if (clima == 2) {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Happy Happy')">Happy Happy</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Hoy si tengo el día')">
						Hoy si tengo el día
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Estoy muy contento')">
						Estoy muy contento
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Feliz y con ganas de darlo todo')">
						Feliz y con ganas de darlo todo
					</button>
				</div>
			}
			@if (clima == 1) {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('A tope de power')">A tope de power</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Ready literalmente')">
						Ready literalmente
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Estoy bien')">Estoy bien</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Con energía para empezar')">
						Con energía para empezar
					</button>
				</div>
			}
			@if (clima == -1) {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Estoy OFF')">Estoy OFF</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Sin ganas de nada')">
						Sin ganas de nada
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Con poca energía')">Con poca energía</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Low Battery')">Low Battery</button>
				</div>
			}
			@if (clima == -2) {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Hoy no tengo el día')">
						Hoy no tengo el día
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Mejor que no se me acerquen')">
						Mejor que no se me acerquen
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Hoy no me apetece trabajar')">
						Hoy no me apetece trabajar
					</button>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<button mat-button class="boton-tandem" style="width: 30vw !important" (click)="sendFastClima('Estoy de muy mal humor')">
						Estoy de muy mal humor
					</button>
				</div>
			}

			<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 24px">
				<button mat-button class="btn-feedbalia" (click)="selectOtro()">Escribir otro motivo</button>
			</div>
		</mat-dialog-content>
	} @else {
		<mat-dialog-content class="mat-typography">
			<div fxLayout="row">
				<div style="opacity: 70%; padding: 12px" fxLayoutAlign="start center" (click)="volverFast()"><mat-icon>arrow_back</mat-icon> Volver</div>
			</div>
			<div fxLayout="row">
				<textarea class="form-control" [(ngModel)]="motivo" placeholder="Escribe aqui el motivo" style="height: 80px; width: 40vw"></textarea>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
				<button mat-button class="btn-feedbalia" (click)="saveClima()">
					{{ "general.button.send" | translate }}
				</button>
			</div>
		</mat-dialog-content>
	}
} @else {
	<mat-dialog-content class="mat-typography">
		<div fxLayout="row">
			<textarea class="form-control" [(ngModel)]="motivo" placeholder="Escribe aqui el motivo" style="height: 80px; width: 40vw"></textarea>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
			<button mat-raised-button class="btn-feedbalia" (click)="saveClima()">
				{{ "general.button.send" | translate }}
			</button>
		</div>
	</mat-dialog-content>
}
