@import "/src/@fuse/scss/fuse";

toolbar {
	position: relative;
	display: flex;
	flex: 0 0 auto;
	z-index: 4;

	&.below {
		z-index: 2;
	}

	.mat-toolbar {
		position: relative;
		background: inherit !important;
		color: inherit !important;
	}

	.logo {
		display: flex;
		align-items: center;

		.logo-icon {
			width: 38px;
		}
	}

	.user-button,
	fuse-search-bar,
	.language-button,
	.chat-panel-toggle-button,
	.quick-panel-toggle-button {
		min-width: 64px;
		height: 64px;

		.notifications {
			position: fixed;
			top: 5px;
			padding: 2px;
			border-radius: 4px;
			background: red;
			margin-left: 40px;
			font-size: 12px;
			font-weight: bold;
		}

		@include media-breakpoint("xs") {
			height: 56px;
		}
	}

	fa-icon {
		margin-right: 16px;
		font-size: 18px;
	}

	.navbar-toggle-button {
		min-width: 56px;
		height: 56px;
	}

	.toolbar-separator {
		height: 64px;
		width: 1px;

		@include media-breakpoint("xs") {
			height: 56px;
		}
	}

	.wrap-persons {
		.list-persons {
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 3;
			min-width: 320px;
			max-width: 500px;
			width: 100%;
			padding: 5xp 0;
			background: #fff;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 2px;
			box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
			background-clip: padding-box;

			.person-avatar.avatar-50 {
				img.img-circle {
					width: 50px;
					height: 50px;
					object-fit: cover;
				}
			}

			.item-person {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
				padding: 12px;
				cursor: pointer;
				transition: all 0.5s ease;

				&:hover {
					background-color: #ebebeb;
				}

				&:first-child {
					border-top: none;
				}

				.info-person {
					font-weight: normal;
					line-height: normal;
					color: #666;
				}
			}
		}
	}
}

.notifications-container {
	max-height: 400px;
	text-align: end;
	overflow-y: auto;
	width: 360px;
	max-width: none !important;

	.read-button {
		font-size: 10px !important;
		line-height: 20px;
		right: 0 !important;
		background-color: var(--feedbalia-color-primary-500) !important;
		color: #fff !important;
		margin-right: 16px;
		margin-bottom: 8px;
	}
}
.border-white {
	border: 1.5px solid white;
	padding: 1px;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
}
.bg-readed {
	opacity: 0.5 !important;
}
.red-badge {
	position: relative;
}

.red-badge::after {
	content: "";
	position: absolute;
	top: -5px;
	right: -5px;
	width: 10px;
	height: 10px;
	background-color: red;
	border-radius: 50%;
}

.mat-mdc-menu-panel {
	max-width: none !important;
}
