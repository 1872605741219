@use "@angular/material" as mat;
@import "/src/@fuse/scss/fuse";

codigo-seguridad {
	#security-code {
		width: 100%;
		background-color: #e7e7e8;
		// background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
		// background-size: cover;

		#login-form-wrapper {
			flex: 0.6 0 auto;
			padding: 32px;

			@include media-breakpoint("xs") {
				padding: 16px;
			}

			#securitycode-form {
				width: 480px;
				max-width: 480px;
				padding: 40px 50px;
				text-align: center;
				border-top-style: solid;
				border-top-width: 4px;
				border-radius: 3px;
				@include mat.elevation(16);

				@include media-breakpoint("xs") {
					padding: 24px;
					width: 100%;
				}

				.logo {
					width: 60%;
					margin: 24px auto;
					margin-top: 0;
				}

				.title {
					font-size: 20px;
					margin: 16px 0 32px 0;
				}

				form {
					width: 100%;
					text-align: left;

					mat-form-field {
						width: 100%;
					}
					/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
					mat-checkbox {
						margin: 0;
					}

					.submit-button {
						width: 100%;
						margin: 16px auto;
						display: block;
						font-size: 16px;
						height: 44px;

						@include media-breakpoint("xs") {
							width: 90%;
						}
					}
				}

				button {
					&.google,
					&.facebook {
						width: 192px;
						text-transform: none;
						color: #ffffff;
						font-size: 16px;
						height: 44px;
					}

					@include media-breakpoint("xs") {
						width: 80%;
					}

					&.google {
						background-color: #d73d32;
						margin-bottom: 8px;
					}

					&.facebook {
						background-color: rgb(63, 92, 154);
					}
				}
			}
		}
	}
}
