import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ProjectService } from "app/services/project/project.service";
import { UserService } from "../../../services/user/user.service";

@Component({
	selector: "app-dialog-clima",
	templateUrl: "./dialog-clima.component.html",
	styleUrls: ["./dialog-clima.component.scss"],
})
export class DialogClimaComponent implements OnInit {
	motivo: string;
	clima: number;
	msgError: string;
	projectId: number;
	step = 0;
	projectConfig;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogClimaComponent>,
		private userSvc: UserService,
		private projectSvc: ProjectService,
	) {
		this.motivo = "";
		this.msgError = "";
	}

	ngOnInit(): void {
		this.projectConfig = this.projectSvc.projectConfig;

		this.clima = this.data.value;
		this.projectId = parseInt(this.projectSvc.getProjectId());
	}

	saveClima() {
		this.hideError();

		if (this.motivo === "" && this.data.mandatoryReason) {
			this.msgError = "Debes escribir un mótivo";
		} else {
			this.userSvc.saveClima(this.clima, this.motivo).subscribe(
				(data) => {
					this.dialogRef.close(this.clima);
				},
				(err) => {
					this.msgError = err;
				},
			);
		}
	}

	sendFastClima(texto) {
		this.userSvc.saveClima(this.clima, texto).subscribe(
			(data) => {
				this.dialogRef.close(this.clima);
			},
			(err) => {
				this.msgError = err;
			},
		);
	}
	selectOtro() {
		this.step = 1;
	}
	volverFast() {
		this.step = 0;
	}

	hideError() {
		this.msgError = "";
	}
}
